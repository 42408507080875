<template>
  <div id="page_title">
    <h1> {{ title }} </h1>
  </div>
</template>

<script>

export default {
  name: "PageTitle",
  props: {
    title: String,
  }
}
</script>

<style scoped>
  div#page_title{
    margin: 0 auto 0 0;
  }
  h1{
    margin: 0;
    font-size: 400%;
    display: flex;
  }

  @media screen and (max-width: 640px){
    h1{
      display: block;
    }
  }
</style>
