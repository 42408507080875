<template>
  <div class="members">
    <AppHeader/>
    <PageTitle title="Member" />
    <div id="members">
      <div class="member  animate__animated" v-for="member in membersList" :key="member.id">
        <router-link :to="{name: 'Member', params: {id: member.id}}">
          <img :src="member.img" :alt="member.name">
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AppHeader from '../components/AppHeader.vue'
import PageTitle from '../components/PageTitle.vue'
import {members} from '../lib/members'
import {createDefaultTitle} from '../lib/util';
import "animate.css"

export default{
  name: "Members",
  components: {
    AppHeader,
    PageTitle
  },
  data(){
    return{
      membersList: []
    }
  },
  created(){
    createDefaultTitle();
    this.membersList = members;
  }
}
</script>

<style scoped>
  #members{
    display: grid;
    gap: 26px;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr))  ;
    margin-top: 6%;
    margin-bottom: 50px;
  }

  .member{
    width: 100%;
  }

  .member img{
    width: 100%;

  }

  .member:hover{
    animation: bounce;
    animation-duration: 1s;
  }
</style>
